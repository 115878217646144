import React, { useEffect } from "react";
import Layout from "../componenets/layout";

export default function IndexPage({ data }) {
  return (
    <Layout pageTitle="Station Information Service - Qatar Rail">
      <div className="flex flex-col text-center md:p-5 text-2xl">
        <h1 className="md:text-4xl font-medium title-font md:mb-4">
          Page not found!
        </h1>
        <h1 className="md:text-4xl font-medium title-font md:mb-4">
          الصفحة غير موجودة!
        </h1>
      </div>
      <Redirect />
    </Layout>
  );
}

function Redirect() {
  useEffect(() => {
    window.location.href = "/";
  }, []);

  return (
    <div className="flex flex-col text-center md:pt-20 text-2xl">
      <h1 className="md:text-4xl font-medium title-font md:mb-4">
        Redirecting to the main page...
      </h1>

      <h1 className="md:text-4xl font-medium title-font md:mb-4">
      إعادة التوجيه إلى الصفحة الرئيسية ...
      </h1>
    </div>
  );
}
